.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.pagination li {
  margin: 0 5px;
  display: inline-block;
}

.pagination a {
  text-decoration: none;
  padding: 8px 16px;
  border: 1px solid #2d3635;
  color: #2d3635;
  border-radius: 5px;
  cursor: pointer;
}

.pagination a:hover {
  background-color: #2d3635;
  color: #fff !important;
}

.pagination .active a,
.pagination .active a:hover {
  background-color: #2d3635 !important;
  color: #fff !important;
}
