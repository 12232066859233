.asideBar{
  /* min-height: calc(100vh - 56px); */
  height: calc(100vh - 46px);
  /* max-height: calc(100vh - 46px); */
  width: 13%;
  /* position: sticky; */
  position: fixed;
  top: 46px;
  left: 0;
  z-index: 1000; 
  overflow-y: auto;
}

/* .dev-geral{
  min-height: calc(100vh - 56px);
} */

.page-content{
  width: 87%;
  padding-left: 10px;
  margin-left: 13%;
}

.buttonOpen{
  display: flex;
  justify-content: end;
  /* position: absolute; */
  /* top: 10px; */
  /* left: 370px; */
  /* z-index: 99; */
  padding-top: 10px;
  padding-right: 10px;
  color: white;
}

.buttonClosed{
    display: none;
}

.sidebar_title{
  font-size: 17px;
}

.nome_empresa{
  font-size: 17px;
}

.btnAbrirMenu{
  display: none;
  margin-left: 15px;
  margin-right: 10px;
  width: 50px;
}

@media (max-width: 992px) {
  .asideBar{
    /* min-height: calc(100vh - 56px); */
    position: fixed;
    top: 46px;
    left: -100%; /* Esconde a barra lateral à esquerda da tela */
    width: 100%; /* Ajuste conforme necessário */
    /* height: 100%; */
    height: calc(100vh - 46px);
    /* background-color: #2d3635; */
    background-color: #212529;
    transition: left 0.3s ease;
  }

  .asideBarOpen{
    /* min-height: calc(100vh - 56px); */
    position: fixed;
    top: 46px;
    left: 0;
    z-index: 3;
    width: 100%; /* Ajuste conforme necessário */
    /* height: 100%; */
    height: calc(100vh - 46px);
    /* background-color: #2d3635; */
    background-color: #212529;
    transition: left 0.3s ease;
  }

  .page-content{
    width: 100%;
    padding-left: 0px;
    margin-left: 0px;
  }

  .btnAbrirMenu{
    display: block;
  }

  .sidebar_title {
    font-size: 17px;
  }
  .nome_empresa {
    font-size: 17px;
  }

}

@media (max-width: 1366px) and (min-width: 993px)  { 

  .nome_empresa {
    font-size: 14px;
  }
  .sidebar_title{
    font-size: 14px;
  }

  .sidebar_subtitle{
    font-size: 14px;
  }

}

@media (max-width: 512px)  { 

  .nome_empresa {
    font-size: 14px;
  }
  .sidebar_title{
    font-size: 20px;
  }

  .sidebar_subtitle{
    font-size: 20px;
  }

}

.accordion-item {
  background-color: transparent !important;
  border: 0px !important;
  color: white !important;
}

.accordion-header {
  background-color: transparent !important;
  border: 0px !important;
  color: white !important;
}

.accordion-button{
  padding: 5px !important;
  background-color: transparent !important;
  border: 0px !important;
  color: white !important;
  padding: 0px !important;
  border-color: transparent !important;
  padding-right: 10px !important;
  z-index: 10;
}

.accordion-button:focus{
  border-color: transparent !important;
  box-shadow: none !important;
}
.accordion-button:not(.collapsed){
  border-color: transparent !important;
  box-shadow: none !important;
}

.teste{
  z-index: -1;
}
