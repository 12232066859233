.recuperar_senha_card{
  width: 25%;
  /* margin: 0 auto; */
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
.recuperar-senha-background{
  /* position: absolute; */
  inset: 0;
  background-color: #2c4f55;
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .recuperar_senha_card{
    width: 100%;
    padding: 5px;
    margin: 0 10px;
  }
}