.marginDiv{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.containerHome{
  display: flex;
  padding-top: 50px;
}

.sideCardHome{
  width: 33%;
  padding: 10px;
}

.cardsHome{
  width: 100%;
}

.cardHome {
  width: 23%;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
  transition: box-shadow 0.3s ease;
  border: none;
  background-color: #fff;
  border-radius: 10px;
}

.cardText{
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 130%;
  font-weight: 600;
}

.caption {
  margin-bottom: 2px;
  color: #627183;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* .cardHome:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.6);
} */

.cardHomeTop{
  width: 90%;
  border-radius: 0;
  transition: transform 0.3s ease-in-out;
}
.cardHomeSide{
  width: 90%;
  border-radius: 0;
  transition: transform 0.3s ease-in-out;
}

/* .cardHomeSide:hover{
  transform: scale(1.1);
  z-index: 10;
} */

.cardContainerHome{
 width: 100%;
 display: flex;
 justify-content: space-between; 
 align-items: center; 
 padding: 10px;
 flex-wrap: wrap;
 gap: 10px
}

.cardBloqueados h2{
  color: #f43e61;
  margin-top: 10px;
}
.cardPendentes h2{
  color: #ffa500;
  margin-top: 10px;
}
.cardNormais h2{
  color: #4ab858;
  margin-top: 10px;
}

.cardTotal{
  width: 100%;
  box-shadow: 0 1px 1px 0 rgba(118, 135, 154, 0.24), 0 3px 8px 0 rgba(118, 135, 154, 0.08);
  transition: box-shadow 0.3s ease;
}
/* .cardTotal:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.6);
} */

.textFaturadoParcial{
  font-size: 15px;
}

@media (max-width: 768px) {
  .cardHome{
    width: 100%;
  }
  .cardContainerHome{
    width: 100%;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 10px;
    gap: 10px
   }
   .cardPagar{
    margin-top: 10px;
   }
   .containerHome{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    padding-top: 60px;
  }
  .cardHomeSide{
    width: 100%;
  }
  .sideCardHome{
    width: 100%;
  }
  .cardsHome{
    width: 100%;
  }
  .cardHomeTop{
    width: 100%;
  }
}

@media (max-width: 1366px) {
  .textFaturadoParcial{
    font-size: 13px;
  }
}
