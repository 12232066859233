.notification-dot {
  position: absolute;
  top: -7px; /* Ajuste a distância do topo conforme necessário */
  left: 78px; /* Ajuste a distância da direita conforme necessário */
  width: 17px; /* Ajuste o tamanho do círculo conforme necessário */
  height: 17px; /* Ajuste o tamanho do círculo conforme necessário */
  background-color: red; /* Cor do círculo */
  border-radius: 50%; /* Torna o círculo redondo */
  border: 1px solid #f1f1f1;
}

@media (max-width: 768px) {
  .notification-dot {
    top: -7px; /* Ajuste a distância do topo conforme necessário */
    left: 355px; /* Ajuste a distância da direita conforme necessário */
  }
}
