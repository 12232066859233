.pagination_container {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.pagination_container li {
  margin: 0 5px;
  display: inline-block;
}

.pagination_container a {
  text-decoration: none;
  padding: 4px 8px;
  border: 1px solid #2d3635;
  color: #2d3635;
  border-radius: 5px;
  cursor: pointer;
}

.pagination_container a:hover {
  background-color: #2d3635;
  color: #fff !important;
}

.pagination_container .active a,
.pagination_container .active a:hover {
  background-color: #2d3635 !important;
  color: #fff !important;
}

.X-remove-all{
  color: #ed053d;
  cursor: pointer;
}

.empresa-title{
  color: #ed053d;
  font-size: 1.56rem;
  text-decoration: underline
}

/* .divEmpresa p{
  font-weight: 600;
} */

.ativo-text{
  color: #0ac247;
  font-weight: 600;
}

.inativo-text{
  color: #ed053d; 
  font-weight: 600; 
}

.data-text{
  font-weight: 600;
}
.cidade-text{
  font-weight: 600;
}

.empresa-description{
  color: #8197b1
}
